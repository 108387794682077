// @flow
import React from 'react';
import Layout from '../../components/layout';
import G from '../../components/glossary-link';
import Link from '../../components/link';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import routes from '../../routes';

type Props = {
  frontMatter: FrontMatter,
};

class Blog_2019_06_30_Everything_Mistake_Ive_Ever_Made_Canyoneering extends React.Component<Props> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <h2>Caveats</h2>
        <ul>
          <li>I probably forgot a lot - I'll add things as I remember them.</li>
          <li>
            The events are randomized within the year they occurred. I'll update
            the post as each year concludes.
          </li>
          <li>
            If you have any questions about any of them feel free to ask when
            we're in a canyon together.
          </li>
        </ul>
        <h2>2020</h2>
        <ul>
          <li>
            I provided a rope on a trip with some canyoneers who didn't
            appreciate as much as I do and core shot it. Expensive rope, too.
          </li>
          <li>
            I did a repeat canyon and couldn't remember it at all. My group
            jokingly asked me several times if I had <em>really</em> descended
            the canyon.
          </li>
          <li>
            I chimneyed right through a huge clump of daddy long-legs. After the
            initial brush-off with the help of other canyoneers, I had them
            crawling up my head for the next five minutes. I'm not really afraid
            of spiders but it was still pretty unnerving.
          </li>
          <li>
            I bailed on a really good trip for the second year in a row. I did
            it because of COVID but cases were so low it would have been
            perfectly fine.
          </li>
          <li>
            I got poked in the eye really really bad. It's been months since it
            happened and it still hurts some mornings.
          </li>
          <li>
            I put on my wetsuit too early in a canyon and ended up high-stemming
            with it on. Bad idea. That was my second time in the canyon, too,
            and I made the same mistake the first time through, too.
          </li>
          <li>
            I didn't bring a knee brace on a trip (I almost never need one). My
            knee really started hurting on the last couple of miles on the first
            day and all subsequent days. I pushed through the pain but I regret
            not bringing that brace.
          </li>
          <li>
            I took a path exiting in the dark and we lost 5 or 10 minutes (The
            dark part was intentional).
          </li>
          <li>
            Didn't take a shortcut on an exit and ended up walking an additional
            half mile or something like that.
          </li>
          <li>
            Got my group lost on a hard exit and ended up having to bushwhack
            uphill for a few hundred feet and we wasted probably 30 minutes. I
            didn't think anything of it.
          </li>
          <li>
            When throwing a sand bag over the Pit of Despair in Poe I hit the
            side of the wall. The bag didn't even make it into the water. All
            subsequent throws made it over by a long shot.
          </li>
          <li>
            I got a rope stuck. I was able to safely climb around to the top of
            the rappel, take a picture of the rope, and continue.
          </li>
          <li>
            I thought I could down climb a rappel, slipped, and fell several
            feet into a slide. Ended up being fine and didn't even say anything
            to my crew but it could have ended badly.
          </li>
        </ul>
        <h2>2019</h2>
        <ul>
          <li>
            I insisted on splitting our group into two because I didn't think
            some of the people were ready for the harder canyon. I worry that I
            may have offended some of the people.
          </li>
          <li>
            I accidentally rigged the rope to my <G>rappel device</G> backwards.
            I caught it before I closed the <G>carabiner</G>.
          </li>
          <li>
            I didn't bring enough water in a canyon. It was miserable. At the
            end of the canyon I realized I had another liter at the bottom of my
            pack.
          </li>
          <li>
            We tried to bypass some avalanche debris and we ended up spending
            more time then we would have just going through it.
          </li>
          <li>
            I was out of shape and depressed for most of the year. It prevented
            me from going on at least one trip. I may not include this line.
          </li>
          <li>
            I didn't have rope protection on a drop and created a{' '}
            <G id="rope-groove">groove</G>.
          </li>
          <li>
            I descended a waterfall where the flow was way too high. I learned a
            lot and wrote{' '}
            <Link to={routes.blog_2019_06_15_rocky_mouth}>a blog post</Link>{' '}
            about it.
          </li>
          <li>
            I brought an extra rope through a canyon. I thought it would be
            helpful but it ended up in my bag almost the whole time.
          </li>
          <li>
            I took a group through a canyon and everyone got wet. Someone said
            they hated canyoneering because of that moment. In hindsight I
            probably could have gotten everyone through the canyon dry.
          </li>
          <li>
            I rigged a rappel short and someone had a spicy <G>down climb</G>{' '}
            because of it. People were at the bottom watching and it was
            embarrassing.
          </li>
          <li>
            First hair-stuck-in-rap-device. Took like 30 seconds to lower the
            person and it was a complete non-issue.
          </li>
          <li>
            I got a rope stuck on a rappel with a <G>chockstone</G> in the
            middle of it. I climbed up and pulled the bind out of the chockstone
            and it had tied an alpine butterfly loop-looking knot.
          </li>
          <li>
            I got to a drop that was just barely too hard to down climb. As I
            was peering over the edge, I realized my feet were sliding and I was
            going to fall. I ended up jumping and was perfectly fine. Thinking
            about it, if the drop had been much taller, I probably wouldn't have
            gotten so close.
          </li>
          <li>
            I got a big blister that slowed me down for the remainder of the
            trip.
          </li>
          <li>
            I was out of shape and really slowed down in a long canyon. I just
            hung out at the back of the group while the energetic ones solved
            the problems.
          </li>
          <li>
            I carried a <G>wetsuit</G> through a canyon but didn't want to put
            it on so I broke the first rule of canyoneering - thou shalt not get
            injured trying to stay dry. Except I didn't get injured.
          </li>
          <li>
            I took someone through a canyon full of spiders and they had a freak
            out.
          </li>
        </ul>
        <h2>2018</h2>
        <ul>
          <li>
            I got a <G>hook</G> super stuck. It was kind of a practice canyon so
            someone walked around back to the top of the <G>rappel</G> and
            retrieved it.
          </li>
          <li>
            We underestimated the length of a rappel with a tough <G>pull</G>.
            Last person went <G>DRT</G> and didn't realize there was a{' '}
            <G>knot</G> in one strand. We anchored the knotted strand and I
            talked them through transitioning onto one strand. Took just a
            couple of minutes.
          </li>
          <li>
            I brought too many clothes and food on a trip where we backpacked to
            our canyons.
          </li>
          <li>I tore my tent bag while backpacking to a set of canyons.</li>
          <li>
            I forgot to bring a water filter on a large canyoneering trip.
          </li>
          <li>
            I brought too many clothes and food on in an overnight canyon.
          </li>
          <li>
            I went through a canyon that was far above my skill level and might
            not have made it out were it not for the other person.
          </li>
          <li>
            I abandoned my rendezvous to descend a canyon with friends who were
            not part of the rendezvous.
          </li>
          <li>
            I was in a large group descending a seldom visited canyon. I sent
            someone down a drop without adequately checking the <G>anchor</G>. I
            had them on a <G id="meat-anchor">meat</G> <G>top-rope belay</G>.
            Someone pushed the anchor to see if it would fail and it easily
            failed.
          </li>
          <li>
            I took a beginner through a beginner-friendly canyon but forgot to
            check how much water they had. Near the end I had to run back to the
            car, get more water, and help them up and out of the canyon.
          </li>
          <li>I got serious blisters on my feet on a multi-day canyon.</li>
          <li>
            I underestimated the difficulty of a pair of canyons and we ended up
            only doing one because it was really cold.
          </li>
          <li>
            I forgot to bring knee pads and <G>neoprene</G> socks on a large
            canyoneering trip.
          </li>
          <li>I thought I was in shape for a trip and I wasn't.</li>
          <li>
            I was not physically prepared for a long, strenuous canyon and was
            exhausted on the last day.
          </li>
          <li>
            Someone got stuck for a bit in a skinny canyon, had to take their
            <G>helmet</G> off, and I dropped it into the crack. We went back the
            next day with hooks and retrieved it.
          </li>
          <li>
            I slipped in moss on the last rappel and all the hikers at the
            bottom watched.
          </li>
          <li>
            I had trouble leading a group to a set of canyons in the dark using
            a <G>GPS</G>.
          </li>
          <li>
            I rigged a very poorly placed <G>CEM</G> expecting it to get stuck
            but curious if it would. It did so someone walked back up the drop
            and cleaned it.
          </li>
          <li>
            I went DRT on a large rappel that ended up being short on one side.
            I had someone hook a sling to the short side, transitioned to one
            strand, and finished the rappel. This exact same thing happened on
            the very next rappel and I think I now realize that DRT on long
            rappels where the pull isn't necessarily hard is a dumb idea.
          </li>
          <li>
            I descended a canyon when there were rain clouds surrounding us. It
            thundered at the only rappel and I didn't enjoy the canyon because I
            was too worried about the weather.
          </li>
          <li>
            I was struggling to get a <G>potshot</G> across a large{' '}
            <G>pothole</G> for various reasons. Someone pulled me out of the way
            and threw it since there was someone already in the pothole and
            speed was important.
          </li>
          <li>
            I went into a canyon that we thought would be dry and it turned out
            to have a couple of swims. We needed wetsuits and got really cold.
          </li>
          <li>
            My group needed to pump water and kept putting it off until it was
            too late. We were really thirsty by the end of it.
          </li>
        </ul>
        <h2>2017</h2>
        <ul>
          <li>I slipped and gashed my knee at an awkward rappel start.</li>
          <li>
            I rigged a rappel at a really bad place instead of going back up
            canyon and rigging at a better place.
          </li>
          <li>
            I completely tore a pair of pants on in a <G>high-stemming</G>{' '}
            canyon. It had no rappels so I didn't bring my <G>harness</G> with
            <G>butt protector</G>.
          </li>
          <li>
            I pressured someone to come on a trip with me even though they were
            pretty sick.
          </li>
          <li>
            I was in a group that stuck the rope on a big rappel. One member of
            the group was about to <G>ascend</G> and I strongly pressured them
            to not do it, knowing how much work it is to ascend this drop. We
            eventually got it down by pulling really hard.
          </li>
          <li>
            I took a long time to try and find a place for a <G>toggle</G> and
            we just gave up and used a <G>sand trap</G>.
          </li>
          <li>
            I was in a group that took the wrong road, got lost, and we spent
            probably an hour or more backtracking to get back on track.
          </li>
          <li>
            I encouraged my group to bring wetsuits and we didn't really end up
            needing them.
          </li>
          <li>
            I got frustrated with a large group of canyoneers who were not
            taking things seriously and just expected to be guided through the
            canyon.
          </li>
          <li>
            I accidentally dropped into the wrong canyon. I recognized the first
            drop and we chose to descend the wrong canyon because we knew we had
            enough rope and we didn't want to bother walking back to the
            original canyon.
          </li>
          <li>
            I didn't properly train someone for down climbs and they really
            shredded their body on some tougher down climbs.
          </li>
          <li>
            I was the last person on rappel, a group came up as I was preparing
            to start the rappel, I lost focus, and started rappelling without
            being clipped into the rope. I caught myself before going over the
            edge, but it's something I will never forget.
          </li>
          <li>
            I rigged a drop that was only rigged on a single tree and not two
            separate, independent anchors. A guide in a large group told their
            clients I was stupid. Definitely ask me, Bruce, or Shane about this
            story some time. It was hilarious!
          </li>
          <li>
            I soloed a canyon. When someone in that same canyon discovered what
            I was doing they sarcastically said, "That sounds safe".
          </li>
          <li>
            I got invited by someone to descend a canyon and they didn't bother
            showing up. This was the second year in a row that this happened
            with this person.
          </li>
        </ul>
        <h2>2016</h2>
        <ul>
          <li>
            I refused help from someone who was training to be a guide. They
            needed people to be cooperative so they could focus on learning and
            I was <em>not that</em>. I should have just gone with the flow
            instead of being a jerk.
          </li>
          <li>
            I completely underestimated the difficulty of a canyon and ended up
            taking many hours longer than I thought it would. This is the only
            time to date that I have unintentionally exited after dark. This is
            also the most tired I have ever gotten on a trip.
          </li>
          <li>
            I wore a wetsuit through a long high stemming section, got really
            sweaty, and had to drink a lot of water afterwards.
          </li>
          <li>
            I did a pretty strenuous canyon when really sick and didn't ever eat
            or drink water the whole time because I just felt so sick.
          </li>
          <li>
            I panicked and froze at a very <G id="exposure">exposed</G> climb
            and had to have someone toss down a rope so I could get up safely.
          </li>
          <li>
            I had someone bring a bottle of water without a backpack into a
            skinny canyon. The water bottle survived but was pretty damaged.
          </li>
          <li>
            I was extremely arrogant when descending a short, beginner friendly
            canyon, knowing I would have to wait behind many large, slow groups.
          </li>
          <li>
            I accidentally left a 200' rope in my backpack and descended a
            canyon with it. When I realized it was in there it was already
            completely soaked through so much heavier. This canyon had one of
            the most difficult <G>exits</G> I have ever done.
          </li>
          <li>I yelled at someone for grabbing a frog.</li>
          <li>
            I got mad that someone in my group <G>core shot</G> my rope. I
            should have been mindful that it could have happened. This is the
            only time I have ever had a rope core shot. Entirely preventable.
          </li>
        </ul>
        <h2>2015</h2>
        <ul>
          <li>I didn't go canyoneering nearly enough.</li>
          <li>
            I was throwing up and descended a canyon anyway. I couldn't keep any
            food or water down in the canyon.
          </li>
          <li>
            I had someone go down DRT and the rope was short so I had to perform
            a cut-and-lower (I cut the <G>webbing</G>).
          </li>
          <li>
            What was supposed to be an easy exit turned out to be a very
            difficult problem that took us an hour to solve.
          </li>
          <li>
            I descended a canyon in cold weather and didn't have enough neoprene
            to keep me warm.
          </li>
          <li>
            I descended a drop in a bad spot and it took us an extra 30 minutes
            to get down.
          </li>
          <li>
            I watched some people tossing a hook over a pothole and didn't yell
            for them to simply walk around. The hook fell in and was lost.
          </li>
          <li>
            I descended a canyon during light rain and worried about{' '}
            <G>flash floods</G> rather than enjoying the canyon.
          </li>
          <li>
            I was <G id="spot">spotting</G> someone when it was super muddy.
            They somehow ended up sitting on my face so I had butt mud on my
            face and helmet.
          </li>
          <li>
            I was supposed to make an exposed climb, ended up having to do it
            with muddy feet, got cliffed out, and had to have someone anchor a
            rope and lower me. Probably the most scared I've ever been in all of
            canyoneering.
          </li>
        </ul>
        <h2>2014</h2>
        <ul>
          <li>I did a canyon with a specific person.</li>
          <li>
            I was with a group where none us knew how to get out of a box canyon
            and we spent half an hour wandering up, down, and then back up the
            canyon before finally getting out.
          </li>
          <li>
            I entered a canyon with a high chance of rain, it kind of flash
            flooded, and some of us experienced a scary rock fall.
          </li>
          <li>
            I took someone through a canyon that was a little too far beyond
            their ability and fitness level.
          </li>
          <li>
            A specific person was doing something incredibly stupid and I didn't
            say anything because I wanted to watch them fail.
          </li>
          <li>
            I got my rope tangled in a waterfall and had to untangle them while
            hanging at a rappel station.
          </li>
          <li>
            I was with a large group at a rendezvous, knew where we were
            supposed to drop in, and watched everybody go past it and then start
            to argue about the drop in point, and then pointed out the correct
            location.
          </li>
          <li>
            I didn't eat or drink enough in a canyon and we didn't stop for
            lunch. When hiking out I was exhausted and lost my footing.
          </li>
          <li>
            We went into a canyon in the hot afternoon and got overheated on the
            way out. I ran out of water with like half a mile until the end and
            was pretty miserable.
          </li>
          <li>
            I put my wetsuit on way too early in a canyon and got pretty hot.
          </li>
          <li>
            I underestimated the difficulty of a canyon that I had done before
            and had some people come who had a lot of difficulty with moving
            through the canyon.
          </li>
          <li>
            I tossed a rope and <G>rope bag</G> down and the bag wasn't tied to
            the rope. I had to rappel down to a different spot to retrieve the
            bag and then finish the rappel as normal.
          </li>
          <li>
            I did a canyon with a long exit, one person had a lot of difficulty,
            and we didn't slow down to accommodate them. I don't think they
            enjoyed the canyon nearly as much as the rest of us because of it.
          </li>
          <li>
            Someone on my trip got really tired and we bailed on canyons for the
            rest of the day.
          </li>
          <li>
            I tossed a rope bag to someone who I thought grabbed the rope. It
            ended up falling into a small puddle and it took me a minute to find
            it because it was just under the surface of the water so I couldn't
            see it.
          </li>
          <li>
            I started a difficult down climb, realized I couldn't finish it, and
            had to have someone toss me a rope so I could finish it safely.
          </li>
          <li>
            I forgot to bring beta and didn't realize it until we were at the
            top of the canyon.
          </li>
          <li>
            I got a rope stuck and had to ascend 30 feet or so to get it
            unstuck.
          </li>
          <li>
            I didn't adequately teach my family about proper{' '}
            <G>partner captures</G>
            and watched them have a couple of slips and falls before getting
            them to do it right.
          </li>
          <li>I descended a canyon solo.</li>
          <li>
            I didn't bring a sufficiently large backpack through a canyon and
            was constantly yelled at by a specific person.
          </li>
        </ul>
        <h2>2013</h2>
        <ul>
          <li>
            I bailed on a canyon and said it was because of weather but in
            reality I was scared to lead this group.
          </li>
          <li>
            I didn't speak up enough when I knew someone was going the wrong way
            and we wasted like 10 minutes wandering around.
          </li>
          <li>
            I went with a large group to a local cascade early in the morning,
            did the route, and two of us left most of the group behind so we
            could make it to work on time.
          </li>
          <li>
            I followed the first path I found to a canyon rather than walking to
            the <G>drainage</G>.
          </li>
          <li>
            I didn't pack nearly enough water for a backpacking trip, thinking
            there would be water along the way. I found out on the trail that
            the spring was dry.
          </li>
          <li>I contributed to rope grooves many times in many canyons.</li>
          <li>
            I lost patience with a guided group in front of me and ran up the
            trail to pass them rather than taking the drainage out, which was
            the correct way to go. The guide mentioned to their clients that we
            were going the wrong way and I took offense, even though they were
            right and I was wrong.
          </li>
          <li>
            I took the wrong route up, got cliffed out, and had to carefully
            make my way back down. I considered rappelling because it was so
            treacherous.
          </li>
          <li>
            I got mad that someone ran up to get ahead of us and took the last
            camping spot. Thinking about it still makes me mad.
          </li>
          <li>
            I lost the trail on an exit and we ended up doing a really exposed
            climb to get out.
          </li>
          <li>
            I <G id="zip">zip-lined</G> my backpack down without the person at
            the bottom knowing proper technique for a zip line. They dropped the
            pack and my GPS shattered.
          </li>
          <li>
            I rappelled off a super sketchy anchor without thinking twice about
            it.
          </li>
          <li>
            I unknowingly dropped a water bottle and had to run half a mile back
            to get it.
          </li>
          <li>
            We went up the wrong way to a canyon. I knew it and voiced my
            opinion, but we did it anyway and spent like 20 minutes of time
            wandering.
          </li>
          <li>
            I was leading a canyon and put someone on rappel on a sit start
            without knowing their experience. I discovered when I saw their
            stance that they had never rappelled before.
          </li>
          <li>
            I jumped with a super heavy backpack, did something bad to my knee,
            and ended up walking on it 23 miles that day. My knee was messed up
            pretty bad for months afterward.
          </li>
          <li>
            I was in a group where our DRT was short and we had to carefully
            down climb the last 20 feet. I wouldn't have known how to fix this
            problem at the time.
          </li>
          <li>
            I went up the wrong drainage for a canyon and we ended up only doing
            the last half of the canyon because of it.
          </li>
          <li>
            I was doing several canyons in a row and didn't realize we wouldn't
            be stopping in between canyons to get more food and water and wasn't
            adequately prepared for the last canyon we did before we stopped.
          </li>
          <li>
            I descended a canyon solo while it was super muddy and snowing.{' '}
            <G>PLBs</G> weren't really a thing yet so I didn't have one.
          </li>
        </ul>
        <h2>2012</h2>
        <ul>
          <li>
            I walked into what I thought was a shallow pool of water and it
            turned out to be a pothole.
          </li>
          <li>
            I touched a <G>petroglyph</G>. I thought I would feel some
            connection to the person who created it. I didn't feel anything. I
            later learned that I shouldn't do that.
          </li>
          <li>
            I called something a "stack of rocks" when it was really a{' '}
            <G>cairn</G>.
          </li>
          <li>
            Someone was hesitating jumping into cold water and I pushed them in.
            They later said they got giardia from me pushing them in.
          </li>
          <li>
            I was led by a beginner and didn't realize they were a beginner.
          </li>
          <li>
            I, for a long time, had the mindset that only the methods I learned
            and used are safe and other groups, no matter what, were basically
            unsafe.
          </li>
          <li>
            I criticized our designated leader behind their back. I later
            learned that what they were doing was perfectly safe even though it
            was different from how I had learned it.
          </li>
          <li>I threw a helmet like a Frisbee.</li>
          <li>
            I was a beginner and led a beginner through a canyon. I had them on
            their first rappel, they didn't rig enough friction, and were on a{' '}
            <G id="figure-8-rappel-device">figure 8</G>. As they were rappelling
            their hand got hot and tired and they said they couldn't hold on any
            longer. They dropped the last few feet. Their hand got burned
            through the glove enough that a blister formed.
          </li>
          <li>
            I studied too many canyons and too much beta that I got everything
            confused when I was actually in the canyons.
          </li>
          <li>
            I lied about the number of canyons I had descended to the people I
            was canyoneering with so they would think I wasn't as much of a
            beginner.
          </li>
          <li>
            My group went into a canyon just to explore for a bit and didn't
            bring any water and it was a very hot day out. Someone saw us go in
            and left water bottles for us at the end of the canyon.
          </li>
        </ul>
      </Layout>
    );
  }
}

export default withFrontMatter('2019-06-30-every-mistake')(
  Blog_2019_06_30_Everything_Mistake_Ive_Ever_Made_Canyoneering
);

// const randomizeMistakes = (() => {
//   const randomize = array => {
//     const max = array.length;
//     for (let i = 0; i < max; i++) {
//       const r = Math.floor(Math.random() * max);
//       const tmp = array[i];
//       array[i] = array[r];
//       array[r] = tmp;
//     }
//   };
//
//   const mistakes = `
//     Mistake 1.
//     Mistake 2.
//   `;
//
//   const split = mistakes
//     .split('\n')
//     .map(mistake => mistake.trim())
//     .filter(mistake => mistake)
//     .map(mistake => `<li>${mistake}</li>`);
//
//   randomize(split);
//
//   console.log(split.join('\n'));
// })();
